// strona główna

import React from 'react';
import classnames from 'classnames';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import { Container } from 'components/controls';
import { Blog } from './components';

import styles from 'theme/pages/Home/Home.module.scss';

const Home = () => (
  <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Home')}>
    <Container>
      <HtmlBlock sectionId="HOMEPAGE" />
      <Blog />
    </Container>
  </div>
);

export default Home;
