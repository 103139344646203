// główny layout - górna belka nagłówka

import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BoxArrowRight } from 'react-bootstrap-icons';
import classnames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Gear } from 'react-bootstrap-icons';

import { useGetLayoutLanguages, usePostOverLogout } from 'api';
import { useDispatch, reduxActions, useSelector } from 'store';
import { Container, DropDown, OverLoginUsers, Link } from 'components/controls';
import { Trader } from './components';

import { ReactComponent as FlagPL } from 'assets/flags/pl.svg';
import { ReactComponent as FlagEN } from 'assets/flags/gb.svg';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';
import LoginButton from 'components/containers/LoginButton';

const HeaderTopBar = ({ isAdminPanel }: { isAdminPanel?: boolean }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { profile, overloginUserId } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // pobranie listy dostępnych języków
  const { data: languagesData } = useGetLayoutLanguages();

  const { mutateAsync } = usePostOverLogout();

  // wylogowanie uźytkownika z systemu
  const handleLogout = async () => {
    if (overloginUserId) {
      await mutateAsync();

      if (pathname.includes('/cart/')) {
        navigate('/cart/0');
      }

      dispatch(reduxActions.setCurrentCartId(null));
      dispatch(reduxActions.setOverlogin(null));

      return;
    }
    dispatch(reduxActions.signOut());
  };

  // flagi języków
  const flags: Record<string, ReactElement> = {
    en: <FlagEN />,
    pl: <FlagPL />
  };

  const renderAdminPanelButton = () => {
    if (isAdminPanel) {
      return (
        <Link className={styles.link} to="/">
          <BoxArrowRight />
          <Trans>Wróć do sklepu</Trans>
        </Link>
      );
    }

    if (profile?.role === 'ROLE_ADMIN') {
      return (
        <Link className={styles.link} to="/managment_panel">
          <Gear />
          <Trans>Panel administratora</Trans>
        </Link>
      );
    }

    if (profile?.role === 'ROLE_TRADER') {
      return (
        <Link className={styles.link} to="/managment_panel">
          <Gear />
          <Trans>Panel tradera</Trans>
        </Link>
      );
    }

    return null;
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-HeaderTopBar'
      )}>
      <Helmet>
        {languagesData?.items.map((language, i) => (
          <link
            key={i}
            rel="alternate"
            href={`${window.location.origin}/${language.id}`}
            hrefLang={language.id}
          />
        ))}
      </Helmet>
      <Container>
        <div className={styles.topBar}>
          <div>{profile?.role !== 'ROLE_OPEN_PROFILE' && <Trader />}</div>

          <div>
            {renderAdminPanelButton()}
            <DropDown
              label={
                <span className={styles.language}>
                  {flags[i18n.language]}
                  {t('Język')}
                </span>
              }
              items={
                languagesData?.items.map((lang) => ({
                  label: (
                    <span className={styles.language}>
                      {flags[lang.id]}
                      {lang.name}
                    </span>
                  ),
                  onClick: () => i18n.changeLanguage(lang.id)
                })) || []
              }
              withDropdownIcon={false}
            />

            {profile?.role !== 'ROLE_OPEN_PROFILE' ? (
              <>
                {!overloginUserId && profile?.role !== 'ROLE_USER' && <OverLoginUsers />}

                <button className={styles.authButton} onClick={handleLogout}>
                  {overloginUserId ? t('Wróć na konto') : t('Wyloguj')} <BoxArrowRight />
                </button>
              </>
            ) : (
              <LoginButton />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderTopBar;
