/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import { Grid } from '@mui/material';

import { reduxActions, useDispatch, useSelector } from 'store';
import { useGetLayoutLanguages } from 'api';
import {
  IDynamicUiField,
  IComponentParams,
  IDynamicAction,
  ILoadDataParam,
  IDynamicComponent
} from 'plugins/api/types';
import { Modal } from 'components/controls';
import { useLoadData } from 'plugins/api/endpoints';
import { loadDataUrlParser } from 'plugins/util/parser';
import { SubComponent } from 'plugins/components';

import { DynamicSelect } from './components/DynamicSelect';
import { DynamicDecimal } from './components/DynamicDecimal';
import { DynamicText } from './components/DynamicText';
import { DynamicCheckbox } from './components/DynamicCheckbox';
import { DynamicTranslations } from './components/DynamicTranslations';
import { Action } from './components/Action';

import 'plugins/theme/components/EditPanel/EditPanel.scss';

// typ danych wejściowych
interface IProps {
  title: string;
  isModal?: boolean;
  fields: IDynamicUiField[];
  actions: IDynamicAction[];
  componentParams?: IComponentParams;
  requestParams?: object;
  loadDataParams?: ILoadDataParam;
  pageSymbol: string;
  componentSymbol: string;
  componentsList?: IDynamicComponent[];
}

const EditPanel: FC<IProps> = ({
  title,
  isModal = true,
  fields,
  componentParams,
  requestParams,
  actions,
  loadDataParams,
  pageSymbol,
  componentSymbol,
  componentsList
}) => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  // czy jest widoczny modal
  const [isVisible, setIsVisible] = useState(componentParams?.isEditorInitialVisible);

  // pobieranie globalnego stanu kontekstu danych
  const { contextData } = useSelector((state) => state.dynamicPage);

  console.log('contextData', contextData);

  const loadDataUrl = loadDataUrlParser(loadDataParams?.get_url || '', qs.parse(search));

  // pobranie danych tabeli
  const { data: editPanelData, refetch: refetchEditPanelData } = useLoadData(
    loadDataUrl,
    {},
    { enabled: false }
  );

  // pobranie listy dostępnych języków
  const { data: languagesData } = useGetLayoutLanguages();

  // pobranie danych początkowych
  useEffect(() => {
    if (loadDataParams) {
      refetchEditPanelData();
    }
  }, [loadDataParams]);

  // ustalenie początkowego stanu requestState
  useEffect(() => {
    const arr = fields?.map((field) => ({
      [field.field_symbol]: (editPanelData as any)?.[field.field_symbol] || field.value
    }));

    dispatch(
      reduxActions.setContextData({
        [pageSymbol]: {
          [componentSymbol]: mapValues(
            keyBy(arr, (key) => Object.keys(key)[0]),
            (value) => Object.values(value)[0]
          )
        }
      })
    );
  }, [editPanelData]);

  useEffect(() => {
    setIsVisible(componentParams?.isEditorInitialVisible);
  }, [componentParams?.isEditorInitialVisible]);

  const handleClose = () => {
    setIsVisible(false), componentParams?.fnCallback?.(false);
  };

  const renderContent = (field: IDynamicUiField) => {
    const { value, field_symbol, ...restFields } = field;
    const mapField = {
      field_symbol,
      ...restFields,
      value: (editPanelData as any)?.[field_symbol] || value
    };

    if (field.type === 'select') {
      return (
        <DynamicSelect field={mapField} pageSymbol={pageSymbol} componentSymbol={componentSymbol} />
      );
    }

    if (field.type === 'decimal' || field.type === 'integer' || field.type === 'float') {
      return (
        <DynamicDecimal
          field={mapField}
          pageSymbol={pageSymbol}
          componentSymbol={componentSymbol}
        />
      );
    }

    if (field.type === 'text' || field.type === 'string') {
      return (
        <DynamicText field={mapField} pageSymbol={pageSymbol} componentSymbol={componentSymbol} />
      );
    }

    if (field.type === 'translation') {
      return (
        <DynamicTranslations
          field={mapField}
          pageSymbol={pageSymbol}
          componentSymbol={componentSymbol}
          languagesData={languagesData?.items}
        />
      );
    }

    if (field.type === 'boolean') {
      return (
        <DynamicCheckbox
          field={mapField}
          pageSymbol={pageSymbol}
          componentSymbol={componentSymbol}
        />
      );
    }

    return null;
  };

  const renderSubComponent = () => {
    const subComponentsActionList = actions?.filter((action) => !!action.sub_components_to_refresh);

    return (subComponentsActionList || []).map((subComponentAction) => {
      if (
        componentsList?.find(
          (component) => component.symbol === subComponentAction.sub_components_to_refresh
        )
      ) {
        return (
          <SubComponent
            componentSymbol={subComponentAction.sub_components_to_refresh || ''}
            pageSymbol={pageSymbol}
            parentComponentSymbol={componentSymbol}
          />
        );
      }

      return null;
    });
  };

  const renderActions = (action: IDynamicAction, i: number) => {
    if (action.type) {
      return (
        <div className="EditPanel-ActionWrapper" key={i}>
          <Action
            action={action}
            handleClose={() => handleClose()}
            requestParams={requestParams}
            requestState={contextData}
            componentParams={componentParams}
            pageSymbol={pageSymbol}
          />
        </div>
      );
    }

    return null;
  };

  const renderEditPanel = () => {
    return (
      <div className="EditPanel-ContentWrapper">
        {title && (
          <div className="EditPanel-TitleWrapper">
            <span>{title}</span>
          </div>
        )}

        <div className="EditPanel-Content">
          <Grid container>
            {fields?.map((field, i) => (
              <Grid xs={field.width} key={i}>
                {renderContent(field)}
              </Grid>
            ))}
          </Grid>
        </div>

        {actions && actions.map((action, i) => renderActions(action, i))}
        {renderSubComponent()}
      </div>
    );
  };

  if (isVisible && isModal) {
    return (
      <Modal title={title} onClose={() => handleClose()}>
        {renderEditPanel()}
      </Modal>
    );
  }

  return renderEditPanel();
};

export default EditPanel;
